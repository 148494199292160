<template>
	<AppLayout>
		<template #navigation>
			<div class="sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
				<div class="md:flex md:items-center md:justify-between">
					<div class="flex-1 min-w-0">
						<!-- Profile -->
						<div class="hidden md:flex justify-center items-center">
							<UserAvatar />
							<div>
								<div class="flex items-center">
									<h1 class="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">G'day, {{ userStore.getUserName }}</h1>
								</div>
								<dl class="mt-6 flex flex-col sm:ml-3 sm:-mt-0.5 sm:flex-row sm:flex-wrap">
									<dt class="sr-only">Company</dt>
									<dd class="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
										<FontAwesomeIcon :icon="faBuilding" aria-hidden="true" class="shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
										Duke street studio
									</dd>
									<div v-if="userStore.getUserPosition">
										<dt class="sr-only">Users Position</dt>
										<dd class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
											<FontAwesomeIcon :icon="faUserTie" aria-hidden="true" class="shrink-0 mr-1.5 h-5 w-5 text-success-400" />
											{{ userStore.getUserPosition }}
										</dd>
									</div>
								</dl>
							</div>
						</div>
						<div class="md:hidden flex items-center">
							<h1 class="text-lg sm:text-2xl font-bold leading-7 text-gray-900 sm:leading-9 truncate flex items-center h-16">G'day, {{ userStore.getUserName }}</h1>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template #primary>
			<div class="mt-4">
				<div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
					<h3 class="text-lg leading-6 font-medium text-gray-900">My overview</h3>
					<dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
						<div v-for="item in stats" :key="item.id" class="relative bg-white pt-5 px-4 p-6 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
							<dt>
								<div class="absolute bg-primary-500 rounded-md p-3 flex mt-1">
									<FontAwesomeIcon :icon="item.icon" class="h-6 w-6 text-white" aria-hidden="true" />
								</div>
								<p class="ml-16 text-sm font-medium text-gray-500 truncate">{{ item.name }}</p>
							</dt>
							<dd class="ml-16 flex items-baseline">
								<p class="text-2xl font-semibold text-gray-900">
									{{ item.stat }}
								</p>
								<p :class="[item.changeTypeColor === 'success' ? 'text-green-600' : 'text-red-600', 'ml-2 flex items-baseline text-sm font-semibold']">
									<FontAwesomeIcon v-if="item.changeType === 'increase'" :icon="faArrowUp" class="self-center flex-shrink-0 h-4 w-4" aria-hidden="true" :class="[item.changeTypeColor === 'success' ? 'text-green-600' : 'text-red-600']" />
									<FontAwesomeIcon v-else :icon="faArrowDown" class="self-center flex-shrink-0 h-4 w-4" aria-hidden="true" :class="[item.changeTypeColor === 'success' ? 'text-green-600' : 'text-red-600']" />
									<span class="sr-only"> {{ item.changeType === 'increase' ? 'Increased' : 'Decreased' }} by </span>
									{{ item.change }}
								</p>
							</dd>
						</div>
					</dl>
				</div>
			</div>
		</template>
	</AppLayout>
</template>

<script setup lang="ts">
	import AppLayout from '@layouts/AppLayout.vue';
	import { useUserStore } from '@/modules/user/store';
	import UserAvatar from '@modules/user/components/UserAvatar.vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faAlarmClock, faArrowDown, faArrowUp, faEnvelopesBulk, faInbox, faInboxOut } from '@fortawesome/pro-regular-svg-icons';
	import { faBuilding, faUserTie } from '@fortawesome/pro-light-svg-icons';

	const userStore = useUserStore();

	const stats = [
		{ id: 1, name: 'My tickets', stat: '71', icon: faEnvelopesBulk, change: '22', changeType: 'increase', changeTypeColor: 'danger' },
		{ id: 2, name: 'My tasks', stat: '13', icon: faInbox, change: '2', changeType: 'increase', changeTypeColor: 'danger' },
		{ id: 3, name: 'Tasks due today', stat: '4', icon: faInboxOut, change: '4', changeType: 'decrease', changeTypeColor: 'success' },
		{ id: 4, name: 'Tasks overdue', stat: '0', icon: faAlarmClock, change: '6', changeType: 'decrease', changeTypeColor: 'success' },
	];
</script>
